/* ==========================================================================
Uploader
========================================================================== */

'use strict';

function initFileUploader() {
    // One button theme
    if ($('input[name="onebutton"]').length) {
        $('input[name="onebutton"]').fileuploader({
            theme: 'onebutton',
        });
    }

    // Field theme
    if ($('input[name="fielduploader"]').length) {
        $('input[name="fielduploader"]').fileuploader({
            addMore: true,
        });
    }

    // Thumbnails theme
    if ($('input[name="thumbnails"]').length) {
        $('input[name="thumbnails"]').fileuploader({
            extensions: ['jpg', 'jpeg', 'png', 'gif', 'bmp'],
            changeInput: ' ',
            theme: 'thumbnails',
            enableApi: true,
            addMore: true,
            thumbnails: {
                box:
                    '<div class="fileuploader-items">' +
                    '<ul class="fileuploader-items-list">' +
                    '<li class="fileuploader-thumbnails-input"><div class="fileuploader-thumbnails-input-inner"><span>+</span></div></li>' +
                    '</ul>' +
                    '</div>',
                item:
                    '<li class="fileuploader-item">' +
                    '<div class="fileuploader-item-inner">' +
                    '<div class="thumbnail-holder">${image}</div>' +
                    '<div class="actions-holder">' +
                    '<a class="fileuploader-action fileuploader-action-remove" title="${captions.remove}"><i class="remove"></i></a>' +
                    '<span class="fileuploader-action-popup"></span>' +
                    '</div>' +
                    '<div class="progress-holder">${progressBar}</div>' +
                    '</div>' +
                    '</li>',
                item2:
                    '<li class="fileuploader-item">' +
                    '<div class="fileuploader-item-inner">' +
                    '<div class="thumbnail-holder">${image}</div>' +
                    '<div class="actions-holder">' +
                    '<a class="fileuploader-action fileuploader-action-remove" title="${captions.remove}"><i class="remove"></i></a>' +
                    '<span class="fileuploader-action-popup"></span>' +
                    '</div>' +
                    '</div>' +
                    '</li>',
                startImageRenderer: true,
                canvasImage: false,
                _selectors: {
                    list: '.fileuploader-items-list',
                    item: '.fileuploader-item',
                    start: '.fileuploader-action-start',
                    retry: '.fileuploader-action-retry',
                    remove: '.fileuploader-action-remove',
                },
                onItemShow: function (item, listEl) {
                    var plusInput = listEl.find(
                        '.fileuploader-thumbnails-input'
                    );

                    plusInput.insertAfter(item.html);

                    if (item.format == 'image') {
                        item.html.find('.fileuploader-item-icon').hide();
                    }
                },
            },
            afterRender: function (listEl, parentEl, newInputEl, inputEl) {
                var plusInput = listEl.find('.fileuploader-thumbnails-input'),
                    api = $.fileuploader.getInstance(inputEl.get(0));

                plusInput.on('click', function () {
                    api.open();
                });
            },
            /*
      // while using upload option, please set
      // startImageRenderer: false
      // for a better effect
      upload: {
          url: './php/upload_file.php',
          data: null,
          type: 'POST',
          enctype: 'multipart/form-data',
          start: true,
          synchron: true,
          beforeSend: null,
          onSuccess: function(data, item) {
              setTimeout(function() {
                  item.html.find('.progress-holder').hide();
                  item.renderThumbnail();
              }, 400);
          },
          onError: function(item) {
              item.html.find('.progress-holder').hide();
              item.html.find('.fileuploader-item-icon i').text('Failed!');
          },
          onProgress: function(data, item) {
              var progressBar = item.html.find('.progress-holder');

              if(progressBar.length > 0) {
                  progressBar.show();
                  progressBar.find('.fileuploader-progressbar .bar').width(data.percentage + "%");
              }
          }
      },
      dragDrop: {
          container: '.fileuploader-thumbnails-input'
      },
      onRemove: function(item) {
          $.post('php/upload_remove.php', {
              file: item.name
          });
      },
      */
        });
    }

    // enable fileuploader plugin
    if ($('input[name="dragndrop"]').length) {
        $('input[name="dragndrop"]').fileuploader({
            changeInput:
                '<div class="fileuploader-input">' +
                '<div class="fileuploader-input-inner">' +
                '<img src="assets/img/fileuploader-dragdrop-icon.png">' +
                '<h3 class="fileuploader-input-caption"><span>Drag and drop invoices here</span></h3>' +
                '<p>or</p>' +
                '<div class="fileuploader-input-button"><span>Browse Files</span></div>' +
                '</div>' +
                '</div>',
            theme: 'dragdrop',
            upload: {
                //url: 'php/ajax_upload_file.php',
                url: 'php/ajax_upload_file.php',
                data: null,
                type: 'POST',
                enctype: 'multipart/form-data',
                start: true,
                synchron: true,
                beforeSend: null,
                onSuccess: function (result, item) {
                    var data = {};

                    try {
                        data = JSON.parse(result);
                    } catch (e) {
                        data.hasWarnings = true;
                    }

                    // if success
                    if (data.isSuccess && data.files[0]) {
                        item.name = data.files[0].name;
                        item.html
                            .find('.column-title > div:first-child')
                            .text(data.files[0].name)
                            .attr('title', data.files[0].name);
                    }

                    // if warnings
                    if (data.hasWarnings) {
                        for (var warning in data.warnings) {
                            alert(data.warnings);
                        }

                        item.html
                            .removeClass('upload-successful')
                            .addClass('upload-failed');
                        // go out from success function by calling onError function
                        // in this case we have a animation there
                        // you can also response in PHP with 404
                        return this.onError ? this.onError(item) : null;
                    }

                    item.html
                        .find('.column-actions')
                        .append(
                            '<a class="fileuploader-action fileuploader-action-remove fileuploader-action-success" title="Remove"><i></i></a>'
                        );
                    setTimeout(function () {
                        item.html.find('.progress-bar2').fadeOut(400);
                    }, 400);
                },
                onError: function (item) {
                    var progressBar = item.html.find('.progress-bar2');

                    if (progressBar.length > 0) {
                        progressBar.find('span').html(0 + '%');
                        progressBar
                            .find('.fileuploader-progressbar .bar')
                            .width(0 + '%');
                        item.html.find('.progress-bar2').fadeOut(400);
                    }

                    item.upload.status != 'cancelled' &&
                    item.html.find('.fileuploader-action-retry').length == 0
                        ? item.html
                              .find('.column-actions')
                              .prepend(
                                  '<a class="fileuploader-action fileuploader-action-retry" title="Retry"><i></i></a>'
                              )
                        : null;
                },
                onProgress: function (data, item) {
                    var progressBar = item.html.find('.progress-bar2');

                    if (progressBar.length > 0) {
                        progressBar.show();
                        progressBar.find('span').html(data.percentage + '%');
                        progressBar
                            .find('.fileuploader-progressbar .bar')
                            .width(data.percentage + '%');
                    }
                },
                onComplete: null,
            },
            onRemove: function (item) {
                $.post('./php/ajax_remove_file.php', {
                    file: item.name,
                });
            },
            captions: {
                feedback: 'Drag and drop files here',
                feedback2: 'Drag and drop files here',
                drop: 'Drag and drop files here',
            },
        });
    }
}
